import { RemoveAll } from './../store/now-playlist/now-playlist.actions';
import { PlayVideo } from './../store/app-player/app-player.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EchoesState } from '../store';
import { VersionCheckerService, YoutubeSearch, YoutubeVideosInfo, YoutubePlayerService, NowPlaylistService } from '.';
import { AppApi } from '../api/app.api';
import { HttpClient } from '@angular/common/http';
import { timer, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import * as AppPlayer from '@core/store/app-player';
import * as NowPlaylist from '@core/store/now-playlist';
import { AppPlayerApi } from '../api/app-player.api';
import * as fromNowPlaylist from '@store/now-playlist';

@Injectable({
  providedIn: 'root'
})
export class TogomusicService {

  private fetchedIds = [];
  togoMode: boolean;
  sub: boolean;
  flag: boolean;
  playlist: GoogleApiYouTubeVideoResource[];
  isPlaying: boolean;
  player: YT.Player;
  prevState: number;


  constructor (private store: Store<EchoesState>,
    private appApi: AppApi,

    private playlistService: NowPlaylistService,
    private ysearch: YoutubeSearch,

    private yts: YoutubePlayerService,
    private youtubeVideosInfo: YoutubeVideosInfo,

    private appPlayerApi: AppPlayerApi,
    private http: HttpClient) {
    if (!this.sub) {
      this.sub = true;
      console.log('Started TogoMusic Service');
      this.toggleTogoMode();
      timer(2000).subscribe(
        () => this.init()
      )
    }
    this.store.select(NowPlaylist.getPlaylistVideos).pipe(
    ).subscribe(
      pl => {
        this.playlist = pl;
      }
    )
    this.store.select(AppPlayer.getIsPlayerPlaying).subscribe(
      t => this.isPlaying = t
    );
    this.store.subscribe(r => {
      this.clean(r.player);
    });
  }

  init() {
    this.playlistService.clearPlaylist();
    this.store.select(AppPlayer.getPlayer)
      .pipe(
        take(1)
      )
      .subscribe(
        p => {
          console.log('Polling cycle Started');
          timer(0, 1000).
            pipe(
              mergeMap(
                () => this.http.get('https://music.togometrics.net/services/getplaylist')
              ),
              mergeMap(
                (r: any) => {
                  //console.log('Playlist:', r);
                  let ids = '';
                  r.forEach(
                    v => {
                      if (this.fetchedIds.indexOf(v.src) === -1) {
                        this.fetchedIds.push(v.src);
                        ids += v.src + ','
                      }
                    }
                  )
                  ids = ids.substring(0, ids.length - 1);
                  if (ids.length > 0) {
                    return this.youtubeVideosInfo.fetchVideosData(ids);
                  } else {
                    return of(false);
                  }
                }
              )
            ).subscribe(
              (r: any) => {
                //console.log('TogoVideoFetchResult', r);
                if (r) {
                  this.playlistService.queueVideos(r);
                  // this.store.dispatch(new NowPlaylist.QueueVideos(r));
                  if (this.togoMode) {
                    if (!this.isPlaying && this.playlist[0]) {
                      console.log('To play', this.playlist[0]);
                      this.appPlayerApi.playVideo(this.playlist[0]);
                      // this.nowPlaylistService.updateIndexByMedia(media.id);
                    }
                  }
                }
              }
            )
        }
      )

  }

  toggleTogoMode() {
    this.togoMode = !this.togoMode;/*
    if (this.togoMode && this.playlist[0]) {
      this.appPlayerApi.playVideo(this.playlist[0]);
    }*/
  }

  queue(media: any): any {
    console.log('Queuing to togo:', media);
    this.http.post('https://music.togometrics.net/services/addsong', { user: 'Bramo', type: 0, songlink: media.id }).subscribe();
  }

  delete(media: any) {
    console.log('Deleting:', media);
    this.appPlayerApi.removeVideoFromPlaylist(media);
    this.fetchedIds = this.fetchedIds.filter(id => id !== media.id);
    this.http.post('https://music.togometrics.net/services/delsong', { id: media.id }).subscribe();
  }
  clean(player: AppPlayer.IAppPlayer) {
    const state = player.playerState;
    if (state === 0 && this.togoMode && this.prevState !== 0  && this.prevState !== undefined) {
      this.delete(player.media);
      if (this.playlist.length === 0) {
        this.appPlayerApi.playVideo(this.playlist[0]);
      }
    }
    this.prevState = state;
  }

}
