
  <div class="btn-group player-controls is-flex-row">
    <button *ngFor="let control of controls"
      [title]="control.title"
      class="btn btn-default btn-lg"
      [ngClass]="[control.feature]"
      (click)="handleControl(control)">
      <icon [name]="control.icon"></icon>
    </button>
  </div>
  