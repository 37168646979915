
  <section class="now-playlist ux-maker">
    <div *ngIf="isPlaylistEmpty" class="empty-list text-center" [@flyOut]>
      <icon name="music 4x" class="bg-primary"></icon>
      <article>
        <h3 class="text-primary">Playlist Is Empty</h3>
        <p class="text-primary">Queue Media From Results</p>
      </article>
    </div>
    <ul class="nav nav-list ux-maker nicer-ux">
      <li class="now-playlist-track" #playlistTrack
        [ngClass]="{
          'active': isActiveMedia(video.id, playlistTrack)
        }"
        *ngFor="let video of playlist.videos | search:playlist.filter; let index = index"
        [@flyOut]>
        <now-playlist-track
          [video]="video" [index]="index"
          (remove)="removeVideo($event)"
          (select)="selectVideo(video)"
          (selectTrack)="selectTrackInVideo($event)"
        ></now-playlist-track>
      </li>
    </ul>
  </section>
  