
  <ul class="list-unstyled clearfix">
    <li class="youtube-list-item" [@fadeIn] *ngFor="let media of list">
      <youtube-media
        [media]="media"
        [queued]="media | isInQueue:queued"
        (play)="playSelectedVideo(media)"
        (queue)="queueSelectedVideo(media)"
        (unqueue)="unqueueSelectedVideo(media)"
        (add)="addVideo(media)">
      </youtube-media>
    </li>
  </ul>
  