
  <div class="playlist-cover is-flex-row is-flex-valign">
    <div class="cover-bg" [ngStyle]="{ 'background-image': 'url(' + thumbUrl + ')' }"></div>
    <div class="btn btn-transparent playlist-thumbnail">
      <img [src]="thumbUrl">
    </div>
    <div class="actions is-flex-2">
      <button class="btn btn-lg ux-maker play-media bg-primary"
        (click)="play.emit(playlist)" title="play playlist">
        <icon name="play"></icon>
      </button>
      <button class="btn btn-lg ux-maker play-media bg-primary"
        (click)="queue.emit(playlist)" title="queue playlist">
        <icon name="share"></icon>
      </button>
    </div>
  </div>
  