
  <playlist-cover
    [playlist]="playlist"
    (play)="onPlayPlaylist($event)"
    (queue)="onQueuePlaylist($event)">
  </playlist-cover>
  <section>
    <youtube-list
      [list]="videos"
      [queued]="queuedPlaylist"
      (play)="onPlayVideo($event)"
      (queue)="onQueueVideo($event)"
      (unqueue)="onRemove($event)"
    ></youtube-list>
  </section>
  